import "./styles.scss";
import React, { useEffect, useState } from 'react';
import Modal from 'shared/components/modal';
import { Row, Col, Table, Alert, Space, Tooltip } from 'antd';
import AddGuestPhoneNumberModal from 'modules/website/pages/newDashboard/components/addGuestPhoneNumberModal';
import { PhoneFilled, CloseOutlined, MailFilled } from '@ant-design/icons';
import Popover from "shared/components/popover";
import { deleteGuest } from "modules/website/redux/operators";
import Notification from "shared/components/notification";
import { PostHog } from "shared/services/posthog";
import { downloadCSV } from "shared/utils/helper";

interface props {
    show: boolean
    close: any
    website: any
    isAddPhoneNumberModalVisible: any
    setIsAddPhoneNumberModalVisible: any
}

const ViewSubscribersModal: React.FC<props> = ({ show, close, website, setIsAddPhoneNumberModalVisible, isAddPhoneNumberModalVisible }) => {
    const [openPopup, setOpenPopup] = useState(null)
    const [isLoading, setIsLoaidng] = useState(false)
    const [subscribers, setSubscribers] = useState<any>([])

    const handleCopy = async (type: "Email" | "Phone", value: string) => {
        navigator.clipboard
            .writeText(value)
            .then(() => {
                Notification('success', `${type} copied successfully`);
            })
            .catch(() => {
                Notification('error', `Could not copy ${type}`);
            });

            PostHog.track("email_phone_copy_from_villagers_list_in_dashboard", {type: type})
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: "Relation",
            dataIndex: 'relation_with_mom',
            key: 'relation_with_mom'
        },
        // {
        //     title: 'Phone',
        //     dataIndex: 'phone',
        //     key: 'phone',
        // },
        {
            key: 'action',
            render: (_: any, record: any) => (
                <>
                    <Space size={"large"}>
                        <Tooltip title={`${!!record?.phone ? record?.phone : record?.email}`}>
                            <div className='btn' onClick={() => handleCopy(!!record?.phone ? "Phone" : "Email", !!record?.phone ? record?.phone : record?.email)}>
                                {
                                    !!record?.phone?
                                    <PhoneFilled className="icon" style={{transform: 'rotate(90deg)'}}/>
                                    :
                                    <MailFilled className="icon"/>
                                }
                            </div>
                        </Tooltip>
                        {
                            !!(record?.updated_at) ?
                            <Tooltip title={`${record?.name} has also voted in your game, so you cannot delete this.`}>
                                <div className='btn'><CloseOutlined className="icon" /></div>
                            </Tooltip>
                            :
                            <Popover
                                text={`Are you sure you want to delete ${record?.name}?`}
                                open={openPopup === record?.id}
                                close={() => setOpenPopup(null)}
                                action={() => handleGuestDelete(record)}
                                isLoading={isLoading}
                            >
                                <Tooltip title="Remove villager from your villie">
                                <div className='btn' onClick={() => setOpenPopup(record?.id)}><CloseOutlined className="icon" /></div>
                                </Tooltip>
                            </Popover>
                        }
                        
                    </Space>
                </>
            ),
          },
    ];

    const handleGuestDelete = async (data: any) => {
        setIsLoaidng(true)
        const response = await deleteGuest(data?.website_id, data?.id)
        setIsLoaidng(false)

        if(response.status === "success"){
            setOpenPopup(null)
            setSubscribers(subscribers.filter((subscriber: any) => subscriber.id !== data.id));
        }
        else{
            Notification("error", "These is error. Try again later.")
        }
    }

    const handleModalClose = () => {
        setOpenPopup(null)
        setTimeout(() => {
            close();
        }, 0);
    }

    useEffect(() => {
        setSubscribers([...[...website?.guests ?? []]])
    }, [])

    const handleDownload = () => {
        if (website?.guests?.length > 0) {
            const filteredData = website?.guests.map(({ name, email, phone, relation_with_mom }: any) => ({
                name,
                email,
                phone,
                relation_with_mom
            }));

            downloadCSV(filteredData, "villagers_contacts_list")
        }
    }

    return (
        <>
            <Modal show={show} close={handleModalClose} head={false} title={<>Your Villagers ({subscribers?.length ?? 0})<br/><p style={{fontSize: "14px", textDecoration: "underline", cursor: "pointer", color: "#5363FF", fontFamily: "Poppins Regular"}} onClick={handleDownload}>Download villagers list</p></>}>
                <Row className='viewSubscribersModalContainer'>
                    <Col span={24} style={{marginBottom: "30px"}}>
                        <Alert 
                            description={<> 
                                <a style={{textDecoration: "underline"}} type="primary" 
                                    onClick={() => {
                                        close()
                                        setIsAddPhoneNumberModalVisible(true)
                                    }}
                                >
                                    Click here to add more friends and family.
                                </a>
                                </>

                            }
                            type="success" 
                        />
                    </Col>
                    <Col span={24}>
                        <Table 
                            columns={columns} 
                            dataSource={[...subscribers]?.sort(
                                (a, b) => b.created_at.localeCompare(a.created_at)
                              )} 
                            pagination={false}
                            scroll={{ x: 240 }}
                        />
                    </Col>
                </Row>
            </Modal>

            <AddGuestPhoneNumberModal
                data={{website: { id: website?.id }}}
                show={isAddPhoneNumberModalVisible}
                close={() => setIsAddPhoneNumberModalVisible(false)}
                addedByMom={true}
            />
        </>
    )
}

export default ViewSubscribersModal;