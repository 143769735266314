import "./styles.scss"
import React, { useEffect, useState } from 'react'
import Button from "shared/components/bButton";
import Spinner from "shared/components/spinner";

interface props{
    label?: string
    actionOnYes: any
    actionOnNo: any
    decision?: "No" | "Yes" | null
    isLoading?: boolean
}
const DecisionButtons:React.FC<props> = ({label, decision=null, actionOnYes, actionOnNo, isLoading = false}) => {
    const [isDecisionMade, setIsDecisionMade] = useState<'Yes' | 'No' | null>(decision);

    const handleYes = () => {
        if(isLoading || isDecisionMade === 'Yes') return;
        setIsDecisionMade('Yes');
        actionOnYes()
    };

    const handleNo = () => {
        if(isLoading || isDecisionMade === 'No') return;
        setIsDecisionMade('No');
        actionOnNo()
    };

    useEffect(() => {
        setIsDecisionMade(decision)
    }, [decision])

    return (
        <div className="decisionBtnContainer">
            {!!label && <p className='labelDecision'>{label}</p>}
            <div className='btnBoxDecision'>
                <Button
                    text={(isDecisionMade === "Yes" && isLoading)? <Spinner /> : "Yes"}
                    action={handleYes}
                    classes={['btnD', isDecisionMade === "Yes" ? 'isBtnSeclected' : '']}
                />
                <Button
                    text={(isDecisionMade === "No" && isLoading)? <Spinner /> : "No"}
                    action={handleNo}
                    classes={['btnD', isDecisionMade === "No" ? 'isBtnSeclected' : '']}
                />
            </div>
        </div>
    )
}

export default DecisionButtons