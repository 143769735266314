import React from 'react';
import Modal from 'shared/components/modal';
import { Row, Col, Table } from 'antd';

interface props {
    show: boolean
    close: any
    data: any
}

const columns = [
    {
        title: 'First Name',
        dataIndex: 'donor_first_name',
        key: 'donor_first_name',
    },
    {
        title: 'Last Name',
        dataIndex: 'donor_last_name',
        key: 'donor_last_name',
    },
    {
        title: 'Donation Amount($)',
        dataIndex: 'donation_amount',
        key: 'donation_amount',
    },
];

const ViewDonersModal: React.FC<props> = ({ show, close, data }) => {
    return (
        <>
            <Modal show={show} close={close} head={false} title="Doners">
                <Row className='addMediaLinkModalContainer'>
                    <Col span={24}>
                        <Table columns={columns} dataSource={data} pagination={false}/>
                    </Col>
                </Row>
            </Modal>
        </>
    )
}

export default ViewDonersModal;