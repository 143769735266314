import React, { useState } from 'react'
import { Form } from 'antd';
import { updateUser } from 'modules/user/redux/operators';
import { updateWebsite, getCityAndState, addWebsiteSetting } from 'modules/website/redux/operators';
import { useDispatch, useSelector } from "react-redux";
import { getUser, setUser } from "modules/user/redux/userSlice";
import Notification from 'shared/components/notification';
import DatePicker from 'shared/components/newDatepicker';
import Input from "shared/components/binput";
import Button from "shared/components/bButton";
import { getDueDataOrDobLabel, getMomentObj, getSettingValue, openInNewTab, updateSettingsArray } from "shared/utils/helper";
import Spinner from 'shared/components/spinner';
import { getWebsite, setWebsite } from 'shared/infra/redux/global/global';
import DecisionButtons from 'shared/composedComponents/decisionButtons';
import { PostHog } from 'shared/services/posthog';


interface props{
    // data: any
    // setData: any
}

const UpdateAccountInfoForm: React.FC<props> = ({}) => {
    const data: any = useSelector(getWebsite)
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const user = useSelector(getUser)
    // const [data, setData] = useState<any>([])
    const [isLoading, setIsLoading] = useState(false)
    const [isVisibilityLoading, setIsVisiblityLoading] = useState(false)

    const handleUpdateUserInfo = async (values: any) => {
        if (isLoading) return 0

        const { name, phone, baby_name, partner_name, due_date_or_dob, zip } = values
        const zipDetails: any = {}

        setIsLoading(true)

        const userInfo = await getCityAndState(zip)

        if (userInfo?.status === "success") {
            const place = userInfo?.data?.places[0];
            zipDetails.city = place["place name"];
            zipDetails.state = place?.state;
            zipDetails.country = userInfo?.data?.country;
        }

        const userResponse = await updateUser({ name, phone })
        const websiteResponse = await updateWebsite(data?.id, { baby_name, partner_name, due_date_or_dob, zip, city: zipDetails?.city, state: zipDetails?.state, country: zipDetails?.country, oldPhone: user?.phone })

        setIsLoading(false)

        if (userResponse?.status === "success" && websiteResponse?.status === "success") {
            // setData({ ...data, baby_name, partner_name, zip, city: zipDetails.city, state: zipDetails.state, country: zipDetails.country, due_date_or_dob: getMomentObj(due_date_or_dob)})
            dispatch(setWebsite({website: { ...data, baby_name, partner_name, zip, city: zipDetails.city, state: zipDetails.state, country: zipDetails.country, due_date_or_dob: getMomentObj(due_date_or_dob)}}))
            dispatch(setUser({ user: { ...user, phone, name } }))

            Notification("success", "Details updated successfully.")
        }
        else {
            Notification("error", "There is an error.")
        }
    }

    const handleDueDateOrDobVisibility = async (value: boolean) => {
        setIsVisiblityLoading(true)
        const payload = {
            setting_key: 'hide_due_date_or_dob',
            setting_value: JSON.stringify(value),
        };

        const response = await addWebsiteSetting(data?.id, payload);
        setIsVisiblityLoading(false)

        if (response?.status === 'success') {
            dispatch(setWebsite({website: { ...data, settings: updateSettingsArray(data.settings, response.data.setting) }}))

            Notification("success", `${getDueDataOrDobLabel(data?.due_date_or_dob, data?.created_at)} is now ${value? "hidden" : "visible"}.`)
        } else {
            Notification('error', 'There is an error.');
        }
    };

    return (
        <Form
            name="updateUserInfoForm"
            onFinish={handleUpdateUserInfo}
            autoComplete="off"
            form={form}
            layout={"vertical"}
            initialValues={{ ...user, ...data, due_date_or_dob: getMomentObj(data?.due_date_or_dob)}}
        >
            <div className='updateUserInfoFormContainer'>

                <Input
                    label="Your name"
                    name="name"
                />
                <Input
                    label="Baby's Name"
                    name="baby_name"
                />
                <DatePicker
                    label={getDueDataOrDobLabel(data?.due_date_or_dob, data?.created_at)}
                    name="due_date_or_dob"
                />
                <DecisionButtons 
                    label={`Hide ${getDueDataOrDobLabel(data?.due_date_or_dob, data?.created_at)}?`}
                    actionOnNo={() => handleDueDateOrDobVisibility(false)}
                    actionOnYes={() => {
                        handleDueDateOrDobVisibility(true);
                        PostHog.track("hide_due_date_or_dob", {})
                    }}
                    isLoading={isVisibilityLoading}
                    decision={!!getSettingValue(data?.settings, "hide_due_date_or_dob")? "Yes" : "No"}
                />
                <Input
                    name="partner_name"
                    label="Partner's Name"
                />
                {
                    data?.country === "United States" &&
                    <>
                        <Input
                            name="phone"
                            label='Phone Number'
                            minLength={10}
                        />
                        <Input
                            name="zip"
                            label='Zip Code'
                            required={false}
                            rules={[
                                {
                                    validator: (_: any, value: any) => {
                                        if (!value) {
                                            return Promise.reject(false)
                                        }
                                        if (isNaN(value) || value.trim() === "") {
                                            return Promise.reject('Zip code must be numeric.')
                                        }
                                        if ((value.trim()).length < 5) {
                                            return Promise.reject(`Zip code length must be 5 or digits.`)
                                        }
                                        return Promise.resolve(true)
                                    }
                                }
                            ]}
                        />
                    
                    </>
                }
                
                <Button
                    htmlType='submit'
                    text={isLoading? <Spinner /> : "Update Account"}
                    type="base4"
                    classes={["w-100-p",]}
                />
                <Button
                    text={"Delete Account"}
                    type="base5"
                    classes={["w-100-p", "m-t-1-p"]}
                    action={() => openInNewTab("https://www.villie.com/contact")}
                />
            </div>
        </Form>

    )
}

export default UpdateAccountInfoForm