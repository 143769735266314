import classNames from "classnames";
import "./styles.scss"
import React from 'react'
import { Link, useNavigate } from "react-router-dom";
import useWindowSize from "shared/hooks/use-window-size";
import {Image} from 'antd';
import ProfileDropdown from "../profileDropdown";
import NavbarTabs from "../navbarTabs";
import NavbarEditButtons from "../navbarEditButtons";
import { useDispatch} from "react-redux";
import { setUpgradeModalVisible } from "shared/infra/redux/global/global";
import { isFreeTrialValid } from "shared/utils/helper";
interface props{
    title: any
    website: any
}
const NavigationHeader:React.FC<props> = ({title, website}) => {
    const dispatch = useDispatch()
    const {width} = useWindowSize()
    const navigate = useNavigate()
    
    return (
        <>
        <div className="navigationHeaderContainer">
            {
                width > 450 ?
                <div className="navigationForDesktop">
                    <div className={classNames({"dashboard": title === "Your Dashboard"},{"other": title !== "Your Dashboard"})}>
                        {title}
                    </div>
                    <div className="rightSide">
                        <NavbarTabs tabs={title === "Profile Set Up" ? 
                        [{text:"Dashboard" ,action: () => navigate("/dashboard")}] : []}/>

                        {!(title === "Profile Set Up") && <NavbarEditButtons website={website}/>}
                        
                        <ProfileDropdown website={website}/>
                    </div>
                </div>
                :
                <div className="navigationForMobile">
                    <div className="navigationForMbTop" style={title?.length === 0 ? {justifyContent: "flex-end", gap: "20px"} : {}}>
                        {
                            title === "Profile Set Up" ?
                            <Link to="/dashboard">
                                <Image className="logo" src={"../assets/villie.svg"} preview={false} />
                            </Link>
                            :
                            <div className="other">
                                {title}
                            </div>
                        }
                        
                        <div className="upgradePlanBtn" 
							onClick={() => dispatch(setUpgradeModalVisible({isUpdgradeModalVisible: true}))}
								style={{backgroundColor: website?.package === "tier1" || isFreeTrialValid(website?.free_trial_started_at) ? "#FFD700" : "#a3d080"}}
							>
                            <img className="icon" src={`../assets/newIcons/${website?.package === "tier1" || isFreeTrialValid(website?.free_trial_started_at) ? "super-villie" :"upgrade-icon"}.svg`} alt="heart" />
                        </div>
                        <ProfileDropdown website={website}/>
                    </div>
                    <div className="navigationForMbBottom">
                        {
                            title === "Profile Set Up" ? 
                            <div className="profileSetUp">
                                {title}
                            </div>
                            :
                            <NavbarEditButtons website={website}/>
                        }
                        
                    </div>
                </div>
            }
        </div>
        
        </>
    )
}

export default NavigationHeader