import "./styles.scss"
import React from 'react'
import CParagraph from "shared/components/paragraph";
import { formatDate, getDueDataOrDobLabel, getFirstNameAndLastName, getSettingValue } from "shared/utils/helper";

interface props{
    website: any
}

// const PersonalInfoWidget:React.FC<props> = ({website}) => {
//     return (
//         <div className="personalInfoWidgeContainer">
//             <div>
//                 <CParagraph classes={["personalInfoTitle"]}>{`${getFirstNameAndLastName(website?.village_owner).first_name}${!!website?.partner_name ? ` + ${getFirstNameAndLastName(website?.partner_name)?.first_name}` : ''}`}</CParagraph>
//                 <CParagraph classes={["personalInfoPragraph"]}>{website?.city}, {website?.country}</CParagraph>
//             </div>
//             {
//                 !getSettingValue(website?.settings, "hide_due_date_or_dob") &&
//                 <div>
//                     <CParagraph classes={["personalInfoTitle"]}>{getDueDataOrDobLabel(website?.due_date_or_dob, website?.created_at)}:</CParagraph>
//                     <CParagraph classes={["personalInfoPragraph"]}>{formatDate(website?.due_date_or_dob)}</CParagraph>
//                 </div>
//             }
            
//         </div>
//     )
// }

const PersonalInfoWidget: React.FC<props> = ({ website }) => {
    const settings = website?.settings || [];

    return (
        <div className="personalInfoWidgeContainer">
            <div>
                <CParagraph classes={["personalInfoTitle"]}>
                    {`${getFirstNameAndLastName(website?.village_owner).first_name}${
                        !!website?.partner_name ? ` + ${getFirstNameAndLastName(website?.partner_name)?.first_name}` : ''
                    }`}
                </CParagraph>
                <CParagraph classes={["personalInfoPragraph"]}>
                    {website?.city}, {website?.country}
                </CParagraph>
            </div>
            {
                !getSettingValue(settings, "hide_due_date_or_dob") && (
                    <div>
                        <CParagraph classes={["personalInfoTitle"]}>
                            {getDueDataOrDobLabel(website?.due_date_or_dob, website?.created_at)}:
                        </CParagraph>
                        <CParagraph classes={["personalInfoPragraph"]}>
                            {formatDate(website?.due_date_or_dob)}
                        </CParagraph>
                    </div>
                )
            }
        </div>
    );
};


export default PersonalInfoWidget