import React from "react";
import "./styles.scss";
import Button from 'shared/components/bButton';

interface props{
    heading: string
    hideNumbers?: boolean
    number?: any
    action?: any
    bottomChild?: any
    details?: any
    styles?: any
}

const Card:React.FC<props> = ({heading, hideNumbers, number, details, styles, action = null, bottomChild=null}) => {
  return (
        <div className="card" style={{...styles}}>
            <div className="headerRow">
                <p className={hideNumbers ? "number" : "title"}>{heading}</p>
                {false && <div className="cardIcon">
                    <span role="img" aria-label="gift">
                    🎁
                    </span>
                </div>}
                </div>
                <div className="numberSection">
                    {!hideNumbers && !!number && <h2 className="number">{number}</h2>}
                    {!!details && <p className="growth">{details}</p>}
                    {!!action && <Button
                            action={action.click}
                            text={action.text}
                            type="transparent"
                            classes={["actionButton"]}
                        />}
                    {bottomChild}
                </div>
        </div>
  );
};

export default Card;
